<template>
  <div class="mainPage">
    <p class="pageTitle">账号审单次数报表查询</p>
    <div class="pageWrap">
      <a-form-model layout="inline">
        <p>温馨提示：单次时间范围选择的最大上限为60天</p>
        <a-form-model-item label="选择时间：">
          <a-range-picker
            v-model="times"
            :show-time="{ format: 'HH:mm:ss' }"
            type="datetimerange"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            :allowClear="false"
            @ok="onOk"
            
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleExport" :loading="loading">Export</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-modal v-model="visible" :footer="null">
      <div style="text-align: center">
        <p>注意：时间范围不可超过60天，请重新选择。</p>
        <a-button type="primary" @click="visible=false">ok</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      times: [],
      visible: false,
      loading: false
    }
  },
  mounted () { 
    this.initTime()
  },
  methods: {
    initTime () {
      const startTime = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const endTime = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.times = [startTime, endTime]
    },
    onOk () {
      let m1 = moment(this.times[0]);
      let m2 = moment(this.times[1]);
      const dayDiff = m2.diff(m1, 'day')
      if (dayDiff >= 60) { 
        this.visible = true
        this.initTime()
      }
    },
    async handleExport () { 
      try {
        this.loading = true
        const res = await this.$http.post('/platform/admin/ticketAdminManage/v1/exportReviewStatistics', {
          startTime: moment(this.times[0]).valueOf(),
          endTime: moment(this.times[1]).valueOf()
        })
        location.href = res.data
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
